<template>
  <div class="eprofile-wrapper" v-if="Object.keys(details).length">
    <div class="mb-32 component__header">
      <h1>वस्तुगत विवरण</h1>
    </div>
    <div class="container mb-32">
      <h3 class="mb-16">{{ $store.state.title }}को बारेमा</h3>
      <div class="top__content">
        <p v-if="details.description">
          {{ details.description }}
        </p>
        <NoDataFound v-else />
      </div>
    </div>
    <div class="container mb-32">
      <h3 class="mb-16">{{ $store.state.title }}को तथ्या्क</h3>
      <div class="flex stats__content">
        <div class="count">
          <span>{{ details.population || "-" }}</span>
          जनसंख्या
        </div>
        <div class="count">
          <span>{{ details.ward_count || "-" }}</span>
          वडाहरु
        </div>
        <div class="count">
          <span>{{ details.house_hold_count || "-" }}</span>
          घरधुरी
        </div>
        <div class="count">
          <span>{{ details.area || "-" }}</span>
          क्षेत्रफल (वर्ग किमी)
        </div>
      </div>
    </div>
    <div class="container mb-80 eprofile__image">
      <h3 class="mb-16">नक्शा</h3>
      <img
        src="../../assets/img_eprofile.svg"
        alt="नागरिक वडापत्र"
        width="850"
        height="380"
      />
      <a
        @click.stop="$router.push('/point-of-interest')"
        class="flex btn btn--gradient"
        >नक्सामा हेर्नुहाेस्<i class="arrow-right"></i
      ></a>
    </div>
    <div class="gallery-wrapper">
      <div class="container">
        <h3 class="mb-16">ग्यालरी</h3>
        <Gallery v-if="gallery.length" :data="gallery" />
        <NoDataFound v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import municipalityRepository from "../../repositories/MunicipalityRepository";
import Gallery from "../../components/Gallery.vue";
const NoDataFound = defineAsyncComponent(() =>
  import("../../components/NoDataFound.vue")
);

export default {
  components: { Gallery, NoDataFound },
  data() {
    return {
      details: {},
      gallery: [],
    };
  },
  async mounted() {
    try {
      let { data } = await municipalityRepository.getDetails();
      this.details = data.data;
      this.gallery = data.gallery;
    } catch (error) {
      console.error(error);
    }

    this.$store.dispatch("setLoading", false);
  },
};
</script>

<style lang="scss" scoped>
.eprofile-wrapper {
  .container {
    max-width: 875px;
    padding: 0 20px;
  }
}
.eprofile__image {
  @media all and (max-width: 600px) {
    margin-bottom: 40px;
  }
  position: relative;
  img {
    height: auto;
    width: 100%;
  }
  .btn {
    bottom: 48px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 218px;
    .arrow-right {
      filter: brightness(0) invert(1);
    }
  }
}
.gallery-wrapper {
  background: $neutrals-white;
  border-radius: 20px;
  padding: 42px 54px;
}
</style>
