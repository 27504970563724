<template>
  <div class="gallery">
    <div class="flex gallery__row">
      <div
        class="mb-24 gallery__card"
        v-for="(ele, index) in paginatedItems"
        :key="index"
      >
        <img :src="ele.thumbnail_url" class="gallery__image" />
        <div class="gallery__image-caption">
          <p>{{ ele.caption }}</p>
        </div>
      </div>
    </div>
    <div class="flex gallery__pagination">
      <button v-on:click="previous()" class="flex gallery--previous">
        <i class="arrow-right arrow-left"></i>अघिल्लो
      </button>
      <ul class="flex">
        <li v-for="index in maxPages" :key="index">
          <span
            @click="showPage(index)"
            :class="index == currentPage ? 'active dot' : 'dot'"
            >dot</span
          >
        </li>
      </ul>
      <button v-on:click="next()" class="flex gallery--next">
        अर्को
        <i class="arrow-right arrow-left"></i>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "Gallery",
  props: {
    data: { type: Array, required: true },
  },
  data() {
    this.items = this.data;
    this.maxPages = Math.ceil(this.items.length / 8);

    return {
      items: this.items,
      paginatedItems: this.items,
      currentPage: 1,
      perPage: 8,
      totalRows: this.items.length,
    };
  },
  methods: {
    paginate(page_size, page_number, type) {
      let itemsToParse = this.items;

      this.paginatedItems = itemsToParse.slice(
        page_number * page_size,
        (page_number + 1) * page_size
      );

      if (this.paginatedItems.length == 0) {
        this.currentPage = type == "next" ? 0 : this.maxPages - 1;
        this.paginate(this.perPage, this.currentPage);
      }
    },
    next() {
      this.paginate(this.perPage, this.currentPage, "next");
      this.currentPage = this.currentPage + 1;
    },
    previous() {
      this.currentPage = this.currentPage - 2;
      this.paginate(this.perPage, this.currentPage, "previous");
      this.currentPage = this.currentPage + 1;
    },
    showPage(page) {
      this.currentPage = page - 1;
      this.paginate(this.perPage, this.currentPage, "next");
      this.currentPage = this.currentPage + 1;
    },
  },
  mounted() {
    this.paginate(this.perPage, 0);
  },
};
</script>
<style scoped lang="scss">
.gallery {
  &__row {
    flex-wrap: wrap;
  }
  &__card {
    border-radius: 10px;
    height: 200px;
    margin-right: 16px;
    position: relative;
    width: calc(25% - 12px);
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
  &__image {
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
    width: 100%;
    &-caption {
      background: rgba($neutrals-black, 0.6);
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      bottom: 0;
      color: $neutrals-white;
      padding: 8px 20px;
      position: absolute;
      width: 100%;
      p {
        word-break: break-word;
      }
    }
  }
  &__pagination {
    justify-content: center;
    margin-top: 24px;
    > ul {
      align-items: center;
      margin: 0 94px;
    }
  }
  &--previous,
  &--next {
    background-color: transparent;
    color: $primary;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
    i {
      margin-right: 8px;
    }
    &:hover {
      color: $secondary;
      background: -webkit-linear-gradient(#f97794, #623aa2);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      .arrow-right {
        background-position: -30px 0;
      }
    }
  }
  &--previous i {
    transform: rotate(180deg);
  }
  .dot {
    background-color: $primary-light;
    border-radius: 50%;
    cursor: pointer;
    display: block;
    height: 10px;
    margin: 0 9px;
    text-indent: -9999px;
    width: 10px;
    &:hover,
    &.active {
      background-color: $primary;
    }
  }
}
</style>
